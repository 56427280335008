import { TranslateService } from "@ngx-translate/core";
import { Bitstream } from "../core/shared/bitstream.model";

export function getFileTypeClass(bitstream: Bitstream): string { // data|sample|info
  let fileTypeFieldName = 'local.file.contentType';
  if (bitstream.hasMetadata(fileTypeFieldName)) {
    return bitstream.firstMetadataValue(fileTypeFieldName);
  }
  return 'data';
}

export function getHoverHint(bitstream: Bitstream, translateService: TranslateService): string {
  let fileType = getFileTypeClass(bitstream);
  let fileTitle = bitstream.firstMetadataValue('dc.title');
  let translateStr: string;
  switch (fileType) {
    case 'sample':
      translateStr = translateService.instant('item-page.file-section.file-type.sample');
      return `${translateStr} ${fileTitle}`;
    case 'info':
      translateStr = translateService.instant('item-page.file-section.file-type.info');
      return `${translateStr} ${fileTitle}`;
    default:
      return '';
  }
}
