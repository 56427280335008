import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, } from '@angular/core';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { AuthMethod } from '../../../../core/auth/models/auth.method';

import { isAuthenticated, isAuthenticationLoading } from '../../../../core/auth/selectors';
import { NativeWindowRef, NativeWindowService } from '../../../../core/services/window.service';
import { isEmpty, isNotNull } from '../../../empty.util';
import { AuthService } from '../../../../core/auth/auth.service';
import { HardRedirectService } from '../../../../core/services/hard-redirect.service';
import { URLCombiner } from '../../../../core/url-combiner/url-combiner';
import { CoreState } from '../../../../core/core-state.model';
import { renderAuthMethodFor } from '../log-in.methods-decorator';
import { AuthMethodType } from '../../../../core/auth/models/auth.method-type';
import { DiscoveryConfig } from 'src/config/opara-discovery.config';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'ds-log-in-external-provider',
  templateUrl: './log-in-external-provider.component.html',
  styleUrls: ['./log-in-external-provider.component.scss']
})
@renderAuthMethodFor(AuthMethodType.Shibboleth)
export class LogInExternalProviderComponent implements OnInit, AfterViewInit {

  @ViewChild("oparaDiscovery") discoverySelect!: ElementRef;

  allInstitutions: DiscoveryConfig = environment.institutions;

  /**
   * The authentication method data.
   * @type {AuthMethod}
   */
  public authMethod: AuthMethod;

  /**
   * True if the authentication is loading.
   * @type {boolean}
   */
  public loading: Observable<boolean>;

  /**
   * The shibboleth authentication location url.
   * @type {string}
   */
  public location: string;

  /**
   * Whether user is authenticated.
   * @type {Observable<string>}
   */
  public isAuthenticated: Observable<boolean>;

  public buttonEnabled: boolean = false;

  /**
   * @constructor
   * @param {AuthMethod} injectedAuthMethodModel
   * @param {boolean} isStandalonePage
   * @param {NativeWindowRef} _window
   * @param {AuthService} authService
   * @param {HardRedirectService} hardRedirectService
   * @param {Store<State>} store
   */
  constructor(
    @Inject('authMethodProvider') public injectedAuthMethodModel: AuthMethod,
    @Inject('isStandalonePage') public isStandalonePage: boolean,
    @Inject(NativeWindowService) protected _window: NativeWindowRef,
    private authService: AuthService,
    private hardRedirectService: HardRedirectService,
    private store: Store<CoreState>
  ) {
    this.authMethod = injectedAuthMethodModel;
  }

  ngOnInit(): void {
    // set isAuthenticated
    this.isAuthenticated = this.store.pipe(select(isAuthenticated));

    // set loading
    this.loading = this.store.pipe(select(isAuthenticationLoading));

    // set location
    this.location = decodeURIComponent(this.injectedAuthMethodModel.location);
  }

  ngAfterViewInit(): void {
    this.initializeInstitutionSelect();
  }

  /**
   * Redirect to the external provider url for login
   */
  redirectToExternalProvider() {
    this.authService.getRedirectUrl().pipe(take(1)).subscribe((redirectRoute) => {
      if (!this.isStandalonePage) {
        redirectRoute = this.hardRedirectService.getCurrentRoute();
      } else if (isEmpty(redirectRoute)) {
        redirectRoute = '/';
      }

      let externalServerUrl = window.location.origin + "/Shibboleth.sso/Login";
      const selectedIdp = this.allInstitutions[this.discoverySelect.nativeElement.value]["link"];
      const correctRedirectUrl = new URLCombiner(this._window.nativeWindow.origin, redirectRoute).toString();

      const redirect = `?redirectUrl=${correctRedirectUrl}`;
      externalServerUrl = externalServerUrl + redirect;

      const target = `&target=${window.location.origin}/server/api/authn/shibboleth`
      externalServerUrl = externalServerUrl + target;

      const entityID = `&entityID=${selectedIdp}`;
      externalServerUrl = externalServerUrl+ entityID;

      this.hardRedirectService.redirect(externalServerUrl);
    });
  };

  initializeInstitutionSelect() {
    this.discoverySelect.nativeElement.addEventListener("change", () => {
      this.buttonEnabled  = (this.discoverySelect.nativeElement.value ? true : false);
    })
  }

  getButtonLabel() {
    return `login.form.${this.authMethod.authMethodType}`;
  }
}
