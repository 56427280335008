import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, concatMap, map } from "rxjs";
import { HALEndpointService } from "src/app/core/shared/hal-endpoint.service";
import { ShareDataHttpResponse, ShareDataResponse } from "./nextcloud-access.model";

@Injectable({
  providedIn: 'root'
})
export class NextcloudAccessService {

  constructor(
    private halService: HALEndpointService,
    private http: HttpClient,
  ){};

  /**
   * Request for opening of nextcloud share
   */
  public openNextcloudShare(submissionID: string): Observable<ShareDataResponse> {

    return this.halService.getEndpoint("nextcloudShareCreate")
      .pipe(
        concatMap((endpoint) => this.http.request("POST", endpoint, {
          responseType: "json",
          body: { "workspaceItemID": `${submissionID}` }
        })),
        map((response: ShareDataHttpResponse) => response.data)
      )
  }

  /**
   * Request for closing of nextcloud share
   */
  public closeNextcloudShare(submissionID: string): Observable<ShareDataResponse> {

    return this.halService.getEndpoint("nextcloudShareClose")
      .pipe(
        concatMap((endpoint) => this.http.request("POST", endpoint, {
          responseType: "json",
          body: { "workspaceItemID": `${submissionID}` }
        })),
        map((response: ShareDataHttpResponse) => response.data)
      )
  }

  /**
 * Request for getting a nextcloud share
 */
  public getNextcloudShare(submissionID: string): Observable<ShareDataResponse> {

    return this.halService.getEndpoint("nextcloudShareGet")
      .pipe(
        concatMap((endpoint) => this.http.request("GET", endpoint.replace("{submissionId}", submissionID), {
          responseType: "json",
        })),
        map((response: ShareDataHttpResponse) => response.data)
      )
  }
}
