<div class="thumbnail limit-width">
  <div *ngIf="isThumbnailLoading" class="thumbnail-content outer">
    <div class="inner">
      <div class="centered">
        <ds-themed-loading [spinner]="true"></ds-themed-loading>
      </div>
    </div>
  </div>
  <!-- don't use *ngIf="!isThumbnailLoading" so the thumbnail can load in while the animation is playing -->
  <img *ngIf="src !== null" class="thumbnail-content img-fluid" [ngClass]="{'d-none': isThumbnailLoading}"
        [src]="src" [alt]="alt | translate" (load)="successHandler()" (error)="errorHandler()">
  <div *ngIf="src === null && !isThumbnailLoading" class="thumbnail-content outer">
    <div class="inner">
      <div class="thumbnail-placeholder centered lead">
        {{ placeholder | translate }}
      </div>
    </div>
  </div>
</div>
