import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "ds-password-field",
  templateUrl: "./password-field.component.html",
  styleUrls: ["./password-field.component.scss"]
})
export class PasswordFieldComponent implements OnInit {

  @Input("passwordValue") passwordValue: string;

  public iconShow: string = "fa-eye";
  public iconHide: string = "fa-eye-slash";

  constructor(
    private clipboard: Clipboard,
    private notifService: NotificationsService,
    private translationService: TranslateService
  ) {}


  /**
   * Called on angular init
   */
  public ngOnInit(): void {

    const passwordField = document.querySelector(".password-field");
    const copyButton = passwordField.querySelector<HTMLElement>("#copy-button");
    const showToggle = passwordField.querySelector<HTMLElement>("#show-toggle");
    const passwordInput = passwordField.querySelector<HTMLInputElement>("input[type=password]");
    this.initCopyButton(copyButton, passwordInput);
    this.initPasswordToggle(showToggle, passwordInput);
  }

  public initCopyButton(trigger: HTMLElement, bearer: HTMLInputElement): void {
    trigger.addEventListener("click", () => {
      this.clipboard.copy(bearer.value);
      this.notifService.info(this.translationService.get(
        "submission.sections.nextcloudAccess.form.info.copied"
      ));
    })
  }

  public initPasswordToggle(trigger: HTMLElement, input: HTMLInputElement): void {
    const iconElement = trigger.querySelector("i");
    trigger.addEventListener("click", () => {
      if ("password" === input.type) {
        iconElement.classList.remove(this.iconShow);
        iconElement.classList.add(this.iconHide);
        input.type = "text";
      } else {
        iconElement.classList.remove(this.iconHide);
        iconElement.classList.add(this.iconShow);
        input.type = "password";
      }
    })
  }
}
