import { InjectionToken } from '@angular/core';
import { AUTOCOMPLETE_OFF, DynamicFormControlLayout, serializable } from '@ng-dynamic-forms/core';
import { DsDynamicInputModel, DsDynamicInputModelConfig } from '../ds-dynamic-input.model';

export const DYNAMIC_FORM_CONTROL_TYPE_NEXTCLOUD_ACCESS = 'NEXTCLOUD_ACCESS';

export interface NextcloudAccessModelConfig extends DsDynamicInputModelConfig {
  value?: any;
}

export class NextcloudAccessModel extends DsDynamicInputModel {

  @serializable() readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_NEXTCLOUD_ACCESS;

  constructor(config: NextcloudAccessModelConfig, layout?: DynamicFormControlLayout) {

    super(config, layout);

    this.autoComplete = AUTOCOMPLETE_OFF;
  }

}

export enum CreationState {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  NOTHING = "NOTHING",
  UNKNOWN = "UNKNOWN",
};

export interface ShareDataResponse {
  data?: ShareData,
  error?: {
    errorReason: string
    error: boolean
  },
  state: string,
}

export interface ShareData {
  shareID: number,
  shareLink: string,
  sharePassword: string,
}

export interface ShareDataHttpResponse {
  _links: {
    self: {
      href: string
    }
  },
  data: ShareDataResponse
}

export const CollectionIdProvider = new InjectionToken<string>("collectionIdProvider");

export const SubmissionIdProvider = new InjectionToken<string>("submissionIdProvider");

export const SectionDataProvider = new InjectionToken<string>("sectionDataProvider");
