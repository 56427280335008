import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BehaviorSubject, Observable, Subscription, of as observableOf } from "rxjs";
import { hasValue } from "src/app/shared/empty.util";
import { NotificationsService } from "src/app/shared/notifications/notifications.service";
import { SectionDataObject } from "src/app/submission/sections/models/section-data.model";
import { SectionModelComponent } from "src/app/submission/sections/models/section.model";
import { renderSectionFor } from "src/app/submission/sections/sections-decorator";
import { SectionsType } from "src/app/submission/sections/sections-type";
import { SectionsService } from "src/app/submission/sections/sections.service";
import { CreationState, NextcloudAccessModel, ShareDataResponse } from "./nextcloud-access.model";
import { NextcloudAccessService } from "./nextcloud-access.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "nextcloud-access",
    templateUrl: "./nextcloud-access.component.html",
    styleUrls: ["./nextcloud-access.component.scss"]
  })
  @renderSectionFor(SectionsType.NextcloudAccess)
  export class NextcloudAccessComponent extends SectionModelComponent implements OnInit, OnDestroy {
    @Input() group: FormGroup;
    @Input() model: NextcloudAccessModel;

    @Output() blur: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Variable to track if the section is loading.
     * @type {boolean}
     */
    public isLoading: boolean = true;
    public dataReady: boolean = false;
    public sectionReady: boolean = false;

    public buttonText: string = "submission.sections.nextcloudAccess.form.button.create";

    public subscriptions: Array<Subscription> = new Array<Subscription>();
    public shareData$$: BehaviorSubject<ShareDataResponse> = new BehaviorSubject({
      error: { error: false, errorReason: null },
      data: null,
      state: null
    });
    public shareData$: Observable<ShareDataResponse> = this.shareData$$.asObservable();

    /**
     * Initialize instance variables.
     *
     * @param {ChangeDetectorRef} changeDetector
     * @param {SectionsService} sectionService
     * @param {NextcloudAccessService} nextCloudAccessService
     * @param {string} injectedCollectionId
     * @param {string} injectedSubmissionId
     * @param {SectionDataObject} injectedSectionData
     */
    constructor(
      protected changeDetector: ChangeDetectorRef,
      protected notifService: NotificationsService,
      protected sectionService: SectionsService,
      private translationService: TranslateService,
      private nextCloudAccessService: NextcloudAccessService,
      @Inject("collectionIdProvider") public injectedCollectionId: string,
      @Inject("submissionIdProvider") public injectedSubmissionId: string,
      @Inject("sectionDataProvider") public injectedSectionData: SectionDataObject) {
      super(injectedCollectionId, injectedSectionData, injectedSubmissionId);
    }

    /**
     * Initialize all instance variables and retrieve configuration.
     */
    onSectionInit(): void {
      this.isLoading = false;
    }

    /**
     * Called on angular init
     */
    ngOnInit(): void {

      this.nextCloudAccessService
        .getNextcloudShare(this.submissionId)
        .subscribe((data) => this.shareData$$.next(data));

      this.subscriptions.push(this.shareData$.subscribe((data) => {
        this.setAvailability(data);
        this.setButtonText(data);
        this.setSectionStatus(data);
        if (null !== data && !data?.error.error && CreationState.UNKNOWN !== data?.state) {
          this.isLoading = false;
          this.sectionReady = true;
        } else {
          this.sectionReady = false;
          this.notifService.error(this.translationService.get(
            "submission.sections.nextcloudAccess.error"
          ));
        }
        this.changeDetector.detectChanges();
      }));
    }

    /**
     * Called on angular destroy
     */
    ngOnDestroy(): void {

      this.subscriptions
      .filter((sub) => hasValue(sub))
      .forEach((sub) => sub.unsubscribe());
      this.shareData$$.complete();
    }

    /**
     * Get section status. Because this simple component never requires human interaction, this is basically
     * always going to be the opposite of "is this section still loading". This is not the place for API response
     * error checking but determining whether the step can 'proceed'.
     *
     * @return Observable<boolean>
     *     the section status
     */
    public getSectionStatus(): Observable<boolean> {
      return observableOf(!this.isLoading);
    }

    /**
     * Unsubscribe from all subscriptions, if needed.
     */
    public onSectionDestroy(): void {
      return;
    }

    /**
     * Handle buttons presses with correct function
     */
    public buttonHandler(): void {

      this.isLoading = true;
      this.dataReady = false;
      this.sectionReady = false;

      if (CreationState.NOTHING === this.shareData$$.getValue()?.state) {
        this.nextCloudAccessService
          .openNextcloudShare(this.submissionId)
          .subscribe((data) => this.shareData$$.next(data));
      } else if (CreationState.OPEN === this.shareData$$.getValue()?.state) {
        this.nextCloudAccessService
          .closeNextcloudShare(this.submissionId)
          .subscribe((data) => this.shareData$$.next(data));
      } else if (CreationState.CLOSED === this.shareData$$.getValue()?.state) {
        this.nextCloudAccessService
          .openNextcloudShare(this.submissionId)
          .subscribe((data) => this.shareData$$.next(data));
      }
    }

    /**
     * Set visibility of share information
     */
    private setAvailability(data: ShareDataResponse): void {

      if (CreationState.OPEN === data?.state) {
        this.dataReady = true;
      } else if (CreationState.CLOSED === data?.state || CreationState.NOTHING === data?.state) {
        this.dataReady = false;
      }
    }

    /**
     * Set button text by creation state of share
     */
    private setButtonText(data: ShareDataResponse): void {

      if (CreationState.OPEN === data?.state) {
        this.buttonText = "submission.sections.nextcloudAccess.form.button.close";
      } else if (CreationState.CLOSED === data?.state) {
        this.buttonText = "submission.sections.nextcloudAccess.form.button.reopen";
      } else if (CreationState.NOTHING === data?.state) {
        this.buttonText = "submission.sections.nextcloudAccess.form.button.create";
      }
    }

    /**
     * Set valid state of nextcloud access section
     * @param data
     */
    private setSectionStatus(data: ShareDataResponse): void {
      if (CreationState.CLOSED === data?.state || CreationState.NOTHING === data?.state) {
        this.sectionService.setSectionStatus(this.submissionId, this.sectionData.id, true);
      } else if (CreationState.OPEN === data?.state) {
        this.sectionService.setSectionStatus(this.submissionId, this.sectionData.id, false);
      }
    }
  };
