import { Component, Input, OnInit } from '@angular/core';
import { Bitstream } from '../core/shared/bitstream.model';
import { getFileTypeClass } from '../shared/file-type.util';

/**
 * This component renders a thumbnail depending on the file type of a bitstream.
 * One input parameter of type Bitstream is expected.
 * If no Bitstream is provided, an HTML placeholder will be rendered instead.
 */
@Component({
  selector: 'file-type-thumbnail',
  styleUrls: ['../thumbnail/thumbnail.component.scss'],
  templateUrl: './file-type-thumbnail.component.html',
})
export class FileTypeThumbnailComponent implements OnInit {
  /**
   * The thumbnail Bitstream
   */
  @Input() file: Bitstream;

  src = null;

  /**
   * i18n key of thumbnail alt text
   */
  @Input() alt? = 'thumbnail.default.alt';

  /**
   * i18n key of HTML placeholder text
   */
  @Input() placeholder? = 'thumbnail.default.placeholder';

  /**
   * Whether the thumbnail is currently loading
   * Start out as true to avoid flashing the alt text while a thumbnail is being loaded.
   */
  isThumbnailLoading = true;

  ngOnInit(): void {
    this.src = this.getThumbnail(this.file);
  }

  /**
   * Handle image download errors.
   * fall back to a HTML placeholder
   */
  errorHandler() {
    this.src = null;
    this.isThumbnailLoading = false;
  }

  /**
   * Stop the loading animation once the thumbnail is successfully loaded
   */
  successHandler() {
    this.isThumbnailLoading = false;
  }

  getThumbnail(bitstream: Bitstream): string {
    switch (getFileTypeClass(bitstream)) {
      case 'sample':
        return 'assets/dspace/images/file-type-thumbnails/sample.jpg';
      case 'info':
        return 'assets/dspace/images/file-type-thumbnails/info.jpg';
      case 'data':
        return 'assets/dspace/images/file-type-thumbnails/data.jpg';
      default:
        console.error('Thumbnail file type is unknown!');
    }
  }
}
