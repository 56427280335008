<div class="input-group password-field">
    <input readonly type="password" class="form-control user-select-all" value="{{ passwordValue }}">
    <div class="input-group-append">
      <button id="show-toggle" class="btn btn-outline-secondary" type="button" title="{{ 'submission.sections.nextcloudAccess.form.title.unhide'|translate }}">
        <i class="fa-regular {{ iconShow }}"></i>
      </button>
      <button id="copy-button" class="btn btn-outline-secondary" type="button" title="{{ 'submission.sections.nextcloudAccess.form.title.copy'|translate }}">
        <i class="fa-regular fa-clipboard"></i>
      </button>
    </div>
  </div>
