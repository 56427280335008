<div class="form-row">
  <div class="col-sm-6">
    <div class="mt-2 pl-2" *ngIf="!sectionReady">
      <ds-themed-loading message="Loading..." [showMessage]="true"></ds-themed-loading>
    </div>
    <div *ngIf="sectionReady">
      <button (click)="buttonHandler()" id="share-handler-btn" class="btn btn-outline-primary w-100">{{ buttonText|translate }}</button>
      <div class="mt-2 pl-2" *ngIf="isLoading">
        <ds-themed-loading message="Loading..." [showMessage]="true"></ds-themed-loading>
      </div>

      <div *ngIf="(shareData$|async) as shareData">
        <div class="pl-2 mt-2" *ngIf="dataReady">
          <table class="table">
            <tbody>
              <tr id="tr-share-link">
                <th scope="row">Link</th>
                <td>
                  <a id="nextcloudURL" href="{{ shareData?.data?.shareLink }}" target="_blank">{{ shareData?.data?.shareLink }}</a>
                </td>
              </tr>
              <tr id="tr-share-password">
                <th scope="row">Password</th>
                <td>
                  <ds-password-field [passwordValue]="shareData?.data?.sharePassword"></ds-password-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <span>
      {{"submission.sections.nextcloudAccess.information"|translate}}
    </span>
  </div>
</div>
