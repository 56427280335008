import { Component, Input, OnInit } from '@angular/core';
import { ItemPageFieldComponent } from '../item-page-field.component';
import { Item } from 'src/app/core/shared/item.model';

@Component({
  selector: 'cc-license-logo',
  templateUrl: './cc-license-logo.component.html',
  styleUrls: ['./cc-license-logo.component.scss'],
})
/**
 * This component can be used to represent a cc license logo.
 * The item has to have the 'dc.uri.rights' metadata field set.
 */
export class CCLicenseLogoComponent extends ItemPageFieldComponent implements OnInit {

  @Input() item: Item;

  ccLicenseGranted: boolean;
  ccLicenseUri: string = null;
  ccLicenseName: string;
  ccLogo: string;
  ccLogoImgSrc: string = 'assets/dspace/images/creativecommons/';


  ngOnInit(): void {
    if (this.item.hasMetadata('dc.rights.uri')) {
      console.debug("Item has CC License!");
      this.ccLicenseUri = this.item.firstMetadataValue('dc.rights.uri');

      this.ccLicenseGranted = true;
      this.ccLogo = this.getCCLogo(this.ccLicenseUri);
      this.ccLogoImgSrc += this.ccLogo;

      if (this.item.hasMetadata('dc.rights')) {
        this.ccLicenseName = this.item.firstMetadataValue('dc.rights');
      }
    } else {
      console.debug("Item has no CC license!");
      this.ccLicenseGranted = false;
    }
  }

  getCCLogo(ccUri: string): string {
    switch (true) {
      case ccUri.startsWith('http://creativecommons.org/licenses/by/'):
        return 'cc-by.png';

      case ccUri.startsWith('http://creativecommons.org/licenses/by-sa/'):
        return 'cc-by-sa.png';

      case ccUri.startsWith('http://creativecommons.org/licenses/by-nd/'):
        return 'cc-by-nd.png';

      case ccUri.startsWith('http://creativecommons.org/licenses/by-nc/'):
        return 'cc-by-nc.png';

      case ccUri.startsWith('http://creativecommons.org/licenses/by-nc-sa/'):
        return 'cc-by-nc-sa.png';

      case ccUri.startsWith('http://creativecommons.org/licenses/by-nc-nd/'):
        return 'cc-by-nc-nd.png';

      case ccUri.startsWith('http://creativecommons.org/publicdomain/zero/'):
        return 'cc-zero.png';

      case ccUri.startsWith('http://creativecommons.org/publicdomain/mark/'):
        return 'cc-mark.png';

      default:
        return 'cc-generic.png';
    }
  }

}
