<div>
  <ds-loading *ngIf="(processing$ | async)"></ds-loading>
  <div class="container">
    <h2>{{ 'person.orcid.registry.queue' | translate }}</h2>

    <ds-alert *ngIf="!(processing$ | async) && (getList() | async)?.payload?.totalElements == 0"
              [type]="AlertTypeEnum.Info">
      {{ 'person.page.orcid.sync-queue.empty-message' | translate}}
    </ds-alert>
    <ds-pagination *ngIf="!(processing$ | async) && (getList() | async)?.payload?.totalElements > 0"
                   [paginationOptions]="paginationOptions"
                   [collectionSize]="(getList() | async)?.payload?.totalElements"
                   [retainScrollPosition]="false" [hideGear]="true" (paginationChange)="updateList()">

      <div class="table-responsive">
        <table id="groups" class="table table-sm table-striped table-hover table-bordered">
          <thead>
          <tr class="text-center align-middle">
            <th>{{'person.page.orcid.sync-queue.table.header.type' | translate}}</th>
            <th>{{'person.page.orcid.sync-queue.table.header.description' | translate}}</th>
            <th>{{'person.page.orcid.sync-queue.table.header.action' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let entry of (getList() | async)?.payload?.page" data-test="orcidQueueElementRow">
            <td style="width: 15%" class="text-center align-middle">
              <i [ngClass]="getIconClass(entry)" [ngbTooltip]="getIconTooltip(entry) | translate"
                 class="fa-2x" aria-hidden="true"></i>
            </td>
            <td class="text-center align-middle">
              {{ entry.description }}
            </td>
            <td style="width: 20%" class="text-center">
              <div class="btn-group edit-field">
                <button [ngbTooltip]="getOperationTooltip(entry) | translate" container="body"
                        class="btn btn-outline-primary my-1 col-md" (click)="send(entry)">
                  <i [ngClass]="getOperationClass(entry)"></i>
                </button>
                <button [ngbTooltip]="'person.page.orcid.sync-queue.discard' | translate" container="body"
                        class="btn btn-outline-danger my-1 col-md" (click)="discardEntry(entry)">
                  <i class="fas fa-unlink"></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ds-pagination>
  </div>
</div>
